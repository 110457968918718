<template>
  <g id="g17">
    <g id="g328" class="dut_components">
      <PressurePinView
        ref="pressurepin_view"
        :pressurepinPlot="pressurePins"
        :renderScale="renderScale"
        :sliderScale="sliderScale"
        :svgScale="1"
        :x_offset="2187"
        :y_offset="10493"
        :width="7486"
        :height="5519"
        :btnRenderSide="btnRenderSide"
        :showRefs="showRefs"
        :refSlider="refSlider"
        key="probe_plate_pp"
        @toggleHoverText="toggleHoverText"
      />
    </g>
    <PressureMa26Top v-show="showPlate && sideToggle != 2" />
    <PressureMa26Bottom v-show="showPlate && sideToggle != 1" />
  </g>
</template>
<script>
import interact from "interactjs";
export default {
  name: "PressureMa26",
  props: {
    pressurePins: Array,
    renderScale: Number,
    svgScale: Number,
    sliderScale: Number,
    probeElement: Object,
    sideToggle: Number,
    btnRenderSide: Number,
    showRefs: Boolean,
    showPlate: Boolean,
    refSlider: Number,
  },
  components: {
    PressurePinView: () => import("./PressurePinView.vue"),
    PressureMa26Top: () => import("./PressureMa26Top.vue"),
    PressureMa26Bottom: () => import("./PressureMa26Bottom.vue"),
  },
  data() {
    return {
      position: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    activateDragAndDrop() {
      interact(`.dut_components`).draggable({
        onmove: (event) => {
          this.position.x += event.dx * 12;
          this.position.y -= event.dy * 12;
          event.target.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
        },
      });
    },
    toggleHoverText(payload) {
      this.$emit("toggleHoverText", payload);
    },
  },
  mounted() {
    this.activateDragAndDrop();
  },
};
</script>
